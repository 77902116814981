import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as firebase from 'firebase/app'

// const firebaseConfig = {
//   apiKey: "AIzaSyB9r4nvkf9DxiuWZH8NcuSDQuRYC_esbfA",
//   authDomain: "trinode-homepage.firebaseapp.com",
//   projectId: "trinode-homepage",
//   storageBucket: "trinode-homepage.appspot.com",
//   messagingSenderId: "383627603736",
//   appId: "1:383627603736:web:a55f70b2b0982d82a2786d",
//   measurementId: "G-961SB26XC7"
// };

// firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
