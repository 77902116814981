import { createContact } from '../firebase'
import { useState, useRef, useEffect } from 'react'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@mui/icons-material/Close';

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(true)
  const [active, setActive] = useState(false)
  const [sendable, setSendable] = useState(false)

  const handleClick = async (e) => {
    e.preventDefault()

    const time = new Date()
  
    // const data = {name, email, message, time}

    const data = {
      to: "miikka.kirsila@gmail.com",
      message: {
        subject: `A new contact request from ${name}`,
        text: `A new contact request from ${name} (${email}) with the text: \n\n ${message}`,
        html: `A new contact request from ${name} (${email}) with the text: <br><br> ${message}`,
      },
    }
  
    try {
      await createContact(data)
      setSent(false)
    } catch (e) {
      console.log('Failed to send message')
    }
  }

  const emailRef = useRef(null)

  useEffect(() => {
    if (document.activeElement === emailRef.current) {
      if (email.includes("@") || (email.length === 0)) {
        setActive(false)
      }
      else setActive(true)
    }
  }, [email])

  useEffect(() => {
    let canSend = true

    if (!(email.includes("@") || (email.length === 0))) {
      canSend = false
    }

    if (name.length === 0 || email.length === 0 || message.length === 0) {
      canSend = false
    }

    setSendable(canSend)
  }, [name, email, message])


  return (
    <form>
      <div className="text-center">
        <input type="text" className="form-submit form-shadow my-3" value={name} onChange={e => setName(e.target.value)} placeholder="Syötä nimesi..." />
        {active ? 
          <>
            <input type="text" className="form-submit form-shadow my-3 error" value={email} ref={emailRef} onChange={e => setEmail(e.target.value)} placeholder="Syötä sähköpostiosoitteesi..." />
            <div className="mover">
              <CloseIcon style={{color:"red"}} /> 
            </div>
            <div>
              <p className="text-danger warning-p">Sähköpostiosoite ei ole kelvollinen</p>
            </div>
          </>:
          <>
            <input type="text" className="form-submit form-shadow my-3" value={email} ref={emailRef} onChange={e => setEmail(e.target.value)} placeholder="Syötä sähköpostiosoitteesi..." />
          </>
        }
        <textarea className="form-submit-textarea form-shadow my-3" value={message} onChange={e => setMessage(e.target.value)} placeholder="Sinulla on nyt vapaa sana..." />
      </div>
      {sent ?
        <div className="text-center">
          <button className="btn btn-primary form-button mt-4" onClick={handleClick} disabled={!sendable}>Lähetä viestisi</button>
        </div>:
        <div className="text-center">
          <button className="btn btn-primary form-button mt-4"><Check /></button>
        </div>
      }
    </form>
  )
}

export default ContactForm