import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import trinodeLogo from './trinode_logo_small.png'
import { Container, Row, Col, Button, Nav, Navbar } from "react-bootstrap"
import {
  BrowserRouter as Router
} from 'react-router-dom'
import jere from "./jere.jpg"
import joonas from "./joonas.jpg"
import miikka from "./miikka.jpg"
import Email from '@material-ui/icons/Email'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { Link } from "react-router-dom"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import dot from './green_dot.png'
import wave from "./wave.svg"
import dot_white from './white_dot.png'
import trinode_grey from './trinode_grey.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import 'aos/dist/aos.css'
import AOS from 'aos'
import { useEffect, useRef, useState } from 'react'
import CodeIcon from '@mui/icons-material/Code';
import LanguageIcon from '@mui/icons-material/Language';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Brush from '@mui/icons-material/Brush';
import ContactForm from './modules/contactForm';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  // Initializes AOS system
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  const homeRef = useRef(null)
  const serviceRefLarge = useRef(null)
  const serviceRefSmall = useRef(null)
  const aboutRef = useRef(null)
  const contactRef = useRef(null)
  const customerRef = useRef(null)

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({behavior: "smooth"})
  }

  const handleScrollToServices = (serviceRefLarge, serviceRefSmall) => {
    if (width > 991) {
      serviceRefLarge.current.scrollIntoView({behavior: "smooth"})
    }

    else {
      serviceRefSmall.current.scrollIntoView({behavior: "smooth"})
    }
  }

  const { height, width } = useWindowDimensions();

  return (
    <div className="all" ref={homeRef}>
    <Router>
      <Navbar collapseOnSelect bg="light" expand="xl" className='justify-content-between fixed-top' style={{width:"100vw"}}>
        <Navbar.Brand inline className="px-3">
          <Link style={{color: 'black'}} to='/' onClick={() => handleScrollTo(homeRef)}>
            <img
              src={trinodeLogo}
              height="40"
              className="d-inline-block align-top ml-2"
              alt="React Bootstrap logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" inline />
        <Navbar.Collapse id="responsive-navbar-nav" inline>
          <Nav className="mx-auto">
            <Nav.Link className='mx-lg-3 ml-3' as={Link} to="/" href="/" onClick={() => handleScrollTo(homeRef)}>Koti</Nav.Link>
            <Nav.Link className='mx-lg-3 ml-3' as={Link} to='/' href="/" onClick={() => handleScrollToServices(serviceRefLarge, serviceRefSmall)}>Palvelumme</Nav.Link>
            <Nav.Link className='mx-lg-3 ml-3' as={Link} to='/' href="/" onClick={() => handleScrollTo(aboutRef)}>Meistä</Nav.Link>
            <Nav.Link className='mx-lg-3 ml-3' as={Link} to='/' href="/" onClick={() => handleScrollTo(customerRef)}>Asiakkaamme</Nav.Link>
            <Nav.Link className='mx-lg-3 ml-3' as={Link} to='/' href="/" onClick={() => handleScrollTo(contactRef)}>Ota yhteyttä</Nav.Link>
            {/* <Nav.Link className='mx-lg-3 ml-3' href='/'>In English</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
        <div style={{ marginTop: '60px' }}></div>
        <div className="text-center landing mb-5">
          <Container className="content justify-content-center text-center">
              <Row className="padding-adder">
                <h1>Trinode Software Oy</h1>
              </Row>
              <Row className="sub-header pt-2">
                <p>Ohjelmistokehitystä asiakkaan tarpeisiin räätälöitynä. Ylpeästi Otaniemestä.</p>
              </Row>
              <Row className="mt-4 justify-content-center button-row">
                <div className="button-div-1">
                  <Button className="fam px-4" onClick={() => handleScrollTo(aboutRef)}>Tutustu meihin</Button> 
                </div>
                <div className="button-div-2">
                <Button className="only-outline px-4" onClick={() => handleScrollTo(contactRef)}>Ota yhteyttä</Button> 
                </div>
              </Row>
          </Container>
        </div>
        <Container className="large-screens" ref={serviceRefLarge}>
          <Row className="mb-5">
              <h1 data-aos="fade-up" data-aos-delay="100" >Palvelumme<span><img className="dot" src={dot} alt={""}/></span></h1>
              <p data-aos="fade-up" data-aos-delay="100"  className="text-secondary">Tutustu ydinosaamiseemme</p> 
          </Row>
          <Row className="my-5 justify-content-center move-left">
            <div className="holder-2" data-aos="fade-right" data-aos-delay="200">
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <CodeIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Moderneja verkkosivuja</h5>
                    </Row>
                    <Row>
                      <p>Avullamme sivustonne näyttää varmasti modernilta - seuraamme aktiivisesti suunnittelun trendejä ja varmistamme parhaan mahdollisen käyttäjäkokemuksen.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div> 
            <div className="holder-2" data-aos="fade-left" data-aos-delay="200">   
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <LanguageIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Data-analyysiä</h5>
                    </Row>
                    <Row>
                      <p>Voisiko yrityksesi hyödyntää kerättyä dataa entistä paremmin? Me näytämme mihin sekä avointa että yrityksenne dataa voidaan käyttää.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="justify-content-center p-rmr pb-5 move-left">
            <div className="holder-2" data-aos="fade-right" data-aos-delay="400">
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <AutoGraphIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Strategiaa</h5>
                    </Row>
                    <Row>
                      <p>Tarvitseeko yrityksesi matemaattista tukea ja tuoretta näkökulmaa strategisille valinnoille ja päätöksille? Me autamme yrityksenne huipputuloksiin.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div> 
            <div className="holder-2" data-aos="fade-left" data-aos-delay="600">   
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <Brush className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Asiakaslähtöisiä palveluita</h5>
                    </Row>
                    <Row>
                      <p>Oletko suunnittelemassa palvelua, mutta et tiedä miten edetä? Olemme olleet monen palvelun kehittämisessä mukana ja voimme auttaa teitä onnistumaan.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="justify-content-center" data-aos="fade-right" data-aos-delay="200">
            <Button className="only-outline-green" onClick={() => handleScrollTo(contactRef)}>Kiinnostuitko? Ota yhteyttä!</Button>
          </Row>
        </Container>
        <Container className="small-screens" ref={serviceRefSmall}> 
          <div>
            <Row className="mt-5">
              <h1 data-aos="fade-left" data-aos-delay="100" >Palvelumme<span><img className="dot" src={dot} alt={""}/></span></h1>
              <p data-aos="fade-left" data-aos-delay="100"  className="text-secondary">Tutustu ydinosaamiseemme</p> 
            </Row>
          </div>
          <Row className="mb-5 justify-content-center">
            <div className="holder-2" data-aos="fade-right" data-aos-delay="200">
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <CodeIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Moderneja verkkosivuja</h5>
                    </Row>
                    <Row>
                      <p>Avullamme sivustosi näyttää varmasti modernilta - seuraamme aktiivisesti suunnittelun trendejä ja varmistamme parhaan mahdollisen käyttäjäkokemuksen.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div> 
            <div className="holder-2" data-aos="fade-left" data-aos-delay="200">   
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <LanguageIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Data-analyysiä</h5>
                    </Row>
                    <Row>
                      <p>Voisiko yrityksesi hyödyntää kerättyä dataa entistä paremmin? Me näytämme mihin sekä avointa että yrityksenne dataa voidaan käyttää.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="justify-content-center p-rmr pb-5">
            <div className="holder-2" data-aos="fade-right" data-aos-delay="200">
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <AutoGraphIcon className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Strategiaa</h5>
                    </Row>
                    <Row>
                      <p>Tarvitseeko yrityksesi matemaattista tukea ja tuoretta näkökulmaa strategisille valinnoille ja päätöksille? Me autamme yrityksenne huipputuloksiin.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div> 
            <div className="holder-2" data-aos="fade-left" data-aos-delay="200">   
              <div className="service-card py-4" >
                <Row className="px-3">
                  <Col xs={3} className="text-center align-center">
                    <Brush className="text-p icons" style={{fontSize:40}}/>
                  </Col>
                  <Col xs={9}>
                    <Row >
                      <h5>Asiakaslähtöisiä palveluita</h5>
                    </Row>
                    <Row>
                      <p>Oletko suunnittelemassa palvelua, mutta et tiedä miten edetä? Olemme olleet monen palvelun kehittämisessä mukana ja voimme auttaa teitä onnistumaan.</p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="justify-content-center" data-aos="fade-left" data-aos-delay="200">
            <Button className="only-outline-green" onClick={() => handleScrollTo(contactRef)}>Kiinnostuitko? Ota yhteyttä!</Button>
          </Row>
        </Container>
        <Container className="pt-5">
          <hr className="h-divider" ref={aboutRef}/>
        </Container>
        <Container>
          <Row className="mt-5 pt-5">
            <Col lg={6}>
              <Row className="pb-4">
                <h1 data-aos="fade-up" data-aos-delay="100">Tarinamme<span><img className="dot" src={dot} alt={""}/></span></h1>
                <p data-aos="fade-up" data-aos-delay="100" className="text-secondary">Yliopistolaskurista konsultointiin</p>
              </Row>
              <Row className="pb-5" data-aos="fade-up" data-aos-delay="100">
                <p>Yhteinen matkamme sai alkunsa sananmukaisesti “eräänä synkkänä ja myrskyisenä yönä”, jolloin Jere sai idean korkeakouluhakijoiden elämän helpottamiseksi. Muutaman kuukauden intensiivisen työskentelyn päätteeksi onnistuimme julkaisemaan ensimmäisen version korkeakouluun.com -verkkosivusta.</p>
                <p>Ensimmäinen projektimme sai sen verran näkyvyyttä, että aloimme saada työtarjouksia ja päätimmekin perustaa Trinode Software Oy:n. Aktiiviset ja monialaiset työntekijämme ovat saaneet kiitosta eritoten startup-yritysten puolelta. Olemme tarjonneet asiakkaillemme kattavia palveluita, joiden tavoitteena on ollut koko lopputuotteen parantaminen.</p>
              </Row>
              <Row>
                <h5 className="text-p pb-3" data-aos="fade-up" data-aos-delay="100">Tavoitteemme</h5>
                <p data-aos="fade-up" data-aos-delay="100">Tavoitteenamme on tuoda yhteen asiantuntevia ja innokkaita opiskelijoita sekä yrityksiä, jotka haluavat tulevaisuuden huippuosaajia tuomaan tuoretta näkökulmaa omiin tuotteisiinsa.</p>
              </Row>
            </Col>
            <Col lg={6} className="padding-adder-4">
              <Row className="padding-adder-1">
                <div className="picture-container mx-2" data-aos="fade-left" data-aos-delay="300">
                  <img src={jere} alt={""} className="image" />
                  <div className="hover text-center">
                    <div className="text-mover px-2">
                      <h5 className="text-light">Jere Niemi</h5>
                      <p>CEO, co-founder</p>
                      <hr/>
                      <a className="text-white" href="mailto:jere.niemi@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                      <a className="text-white" href="https://linkedin.com/in/jere-niemi"><LinkedInIcon style={{fontSize: 35}} /></a>
                    </div>
                  </div>
                </div> 
                <div className="holder mx-2" data-aos="fade-left" data-aos-delay="700">
                  <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
                </div>
              </Row>
              <Row className="padding-adder-2">
                <div className="holder mx-2" data-aos="fade-left" data-aos-delay="700">
                  <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
                </div>
                <div className="picture-container mx-2" data-aos="fade-left" data-aos-delay="800">
                  <img src={joonas} alt={""} className="image" />
                    <div className="hover text-center">
                      <div className="text-mover px-2">
                        <h5 className="text-light">Joonas Räikkönen</h5>
                        <p>COO, co-founder</p>
                        <hr/>
                        <a className="text-white" href="mailto:joonas.raikkonen@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                        <a className="text-white" href="https://www.linkedin.com/in/joonas-raikkonen/"><LinkedInIcon style={{fontSize: 35}} /></a>
                      </div>
                    </div>
                </div> 
              </Row>
              <Row className="padding-adder-3">
                <div className="picture-container mx-2" data-aos="fade-left" data-aos-delay="400">
                  <img src={miikka} alt={""} className="image" />
                    <div className="hover text-center">
                      <div className="text-mover px-2">
                        <h5 className="text-light">Miikka Kirsilä</h5>
                        <p>CTO, co-founder</p>
                        <hr/>
                        <a className="text-white" href="mailto:miikka.kirsila@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                        <a className="text-white" href="https://linkedin.com/in/thekirsila"><LinkedInIcon style={{fontSize: 35}} /></a>
                      </div>
                    </div>
                </div> 
                <div className="holder mx-2" data-aos="fade-left" data-aos-delay="700">
                  <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 pb-3" data-aos="fade-left" data-aos-delay="200">
            <Button className="only-outline-green" onClick={() => handleScrollTo(contactRef)}>Kiinnostuitko? Ota yhteyttä!</Button>
          </Row>
        </Container>
        <Row className="mb-3 mt-5" data-aos="fade-left" data-aos-delay="200">
          <Row className="test-1">
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="picture-container mx-2">
              <img src={jere} alt={""} className="image" />
              <div className="hover text-center">
                <div className="text-mover px-2">
                  <h5 className="text-light">Jere Niemi</h5>
                  <p>CEO, co-founder</p>
                  <hr/>
                  <a className="text-white" href="mailto:jere.niemi@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                      <a className="text-white" href="https://linkedin.com/in/jere-niemi"><LinkedInIcon style={{fontSize: 35}} /></a>
                </div>
              </div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
          </Row>
        </Row>
        <Row className="my-3" data-aos="fade-right" data-aos-delay="200">
          <Row className="test-2">
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="picture-container mx-2">
              <img src={joonas} alt={""} className="image" />
                <div className="hover text-center">
                  <div className="text-mover px-2">
                    <h5 className="text-light">Joonas Räikkönen</h5>
                    <p>COO, co-founder</p>
                    <hr/>
                    <a className="text-white" href="mailto:joonas.raikkonen@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                        <a className="text-white" href="https://www.linkedin.com/in/joonas-raikkonen/"><LinkedInIcon style={{fontSize: 35}} /></a>
                  </div>
                </div>
            </div> 
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
          </Row>
        </Row>
        <Row className="my-2 pb-5" data-aos="fade-left" data-aos-delay="200">
          <Row className="test-3">
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="picture-container mx-2">
              <img src={miikka} alt={""} className="image" />
                <div className="hover text-center">
                  <div className="text-mover px-2">
                    <h5 className="text-light">Miikka Kirsilä</h5>
                    <p>CTO, co-founder</p>
                    <hr/>
                    <a className="text-white" href="mailto:miikka.kirsila@trinode.fi"><Email style={{fontSize: 35, marginRight:"20px"}} /></a>
                    <a className="text-white" href="https://linkedin.com/in/thekirsila"><LinkedInIcon style={{fontSize: 35}} /></a>
                  </div>
                </div>
            </div> 
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
            <div className="holder mx-2">
              <div className="colored-div-1"><Email style={{fontSize:0}} /></div>
            </div>
          </Row>
        </Row>
        <Container fluid className="gray-bg pt-5" ref={customerRef}>
          <Container className="lg-s">
            <Row className="pt-5">
              <h1 data-aos="fade-up" data-aos-delay="100">Asiakkaitamme ja projektejamme<span><img className="dot" src={dot} alt={""}/></span></h1>
            </Row>
            <Row className="py-2 text-secondary" data-aos="fade-up" data-aos-delay="100">
              <p>Referensseistä yritys tunnetaan. Tutustu siis tärkeimpiin projekteihimme.</p>
            </Row>
            <Row className="py-5 text-center justify-content-center">
              <Col xl={3} data-aos="fade-left" data-aos-delay="100">
                <div className="company-card-trinode pt-4">
                  <div className="trinode"></div>
                  <h5>korkeakouluun.com</h5>
                  <p>Työkalu korkeakouluhaun helpottamiseksi</p>
                  <div className="link-align">
                    <a className="company-link-trinode" href="https://korkeakouluun.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                  </div>
                </div>
              </Col>
              <Col xl={3} data-aos="fade-left" data-aos-delay="500">
                <div className="company-card-growpolis pt-4">
                  <div className="growpolis mt-4"></div>
                  <h5>Growpolis</h5>
                  <p>Yksinkertaistamaan yrityksesi matkaa huipulle</p>
                  <div className="link-align">
                    <a className="company-link-growpolis" href="https://growpolis.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                  </div>
                </div>
              </Col>
              <Col xl={3} data-aos="fade-left" data-aos-delay="1000">
                <div className="company-card-feelado pt-1">
                  <div className="feelado mt-4"></div>
                  <h5>Feelado</h5>
                  <p>Löydä itsellesi mentorisi korkeakouluopiskelijoiden joukosta!</p>
                  <div className="link-align-feelado">
                    <a className="company-link-feelado" href="http://feelado.fi"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                  </div>
                </div>
              </Col>
              <Col xl={3} data-aos="fade-left" data-aos-delay="1500">
                <div className="company-card-veri pt-3">
                  <div className="veri mt-4"></div>
                  <h5>Veri</h5>
                  <p>Understand what you eat. Find your fuel.</p>
                  <div className="link-align">
                    <a className="company-link-veri" href="https://veristable.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="md-s">
            <Row className="pt-5 magic">
              <h1 data-aos="fade-up" data-aos-delay="100">Asiakkaitamme ja projektejamme<span><img className="dot" src={dot} alt={""}/></span></h1>
            </Row>
            <Row className="py-2 text-secondary magic" data-aos="fade-up" data-aos-delay="100">
              <p>Referensseistä yritys tunnetaan. Tutustu siis tärkeimpiin projekteihimme.</p>
            </Row>
            <Row className="py-5 text-center p-left">
              <Col sm={6} data-aos="fade-right" data-aos-delay="100">
                <div className="company-card-trinode pt-4">
                  <div className="trinode"></div>
                  <h5>korkeakouluun.com</h5>
                  <p>Työkalu korkeakouluhaun helpottamiseksi</p>
                  <div className="link-align">
                    <Link className="company-link-trinode"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></Link>
                  </div>
                </div>
              </Col>
              <Col sm={6} data-aos="fade-left" data-aos-delay="300">
                <div className="company-card-growpolis pt-4">
                  <div className="growpolis mt-4"></div>
                  <h5>Growpolis</h5>
                  <p>Yksinkertaistamaan yrityksesi matkaa huipulle</p>
                  <div className="link-align">
                    <Link className="company-link-growpolis"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="py-5 text-center p-left p-b">
              <Col sm={6} data-aos="fade-right" data-aos-delay="100">
                <div className="company-card-feelado pt-1">
                  <div className="feelado mt-4"></div>
                  <h5>Feelado</h5>
                  <p>Löydä itsellesi mentorisi korkeakouluopiskelijoiden joukosta!</p>
                  <div className="link-align-feelado">
                    <Link className="company-link-feelado"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></Link>
                  </div>
                </div>
              </Col>
              <Col sm={6} data-aos="fade-left" data-aos-delay="300">
                <div className="company-card-veri pt-3">
                  <div className="veri mt-4"></div>
                  <h5>Veri</h5>
                  <p>Understand what you eat. Find your fuel.</p>
                  <div className="link-align">
                    <Link className="company-link-veri"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="sm-s">
            <Row className="pt-5">
              <h1 data-aos="fade-up" data-aos-delay="200">Asiakkaitamme ja projektejamme<span><img className="dot" src={dot} alt={""}/></span></h1>
            </Row>
            <Row className="py-2 text-secondary" data-aos="fade-up" data-aos-delay="100">
              <p>Referensseistä yritys tunnetaan. Tutustu siis tärkeimpiin projekteihimme.</p>
            </Row>
            <Row className="py-3 text-center justify-content-center" data-aos="fade-left" data-aos-delay="200">
              <div className="company-card-trinode pt-4">
                <div className="trinode"></div>
                <h5>korkeakouluun.com</h5>
                <p>Työkalu korkeakouluhaun helpottamiseksi</p>
                <div className="link-align">
                  <a className="company-link-trinode" href="https://korkeakouluun.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                </div>
              </div>
            </Row>
            <Row data-aos="fade-right" data-aos-delay="200" className="py-3 text-center justify-content-center">
              <div className="company-card-growpolis pt-4">
                <div className="growpolis mt-4"></div>
                <h5>Growpolis</h5>
                <p>Yksinkertaistamaan yrityksesi matkaa huipulle</p>
                <div className="link-align">
                  <a className="company-link-growpolis" href="https://growpolis.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                </div>
              </div>
            </Row>
            <Row data-aos="fade-left" data-aos-delay="200" className="py-3 text-center justify-content-center">
              <div className="company-card-feelado pt-1">
                <div className="feelado mt-4"></div>
                <h5>Feelado</h5>
                <p>Löydä itsellesi mentorisi korkeakouluopiskelijoiden joukosta!</p>
                <div className="link-align-feelado">
                  <a className="company-link-feelado" href="http://feelado.fi"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                </div>
              </div>
            </Row>
            <Row data-aos="fade-right" data-aos-delay="200" className="pt-3 pb-5 text-center justify-content-center">
              <div className="company-card-veri pt-3">
                <div className="veri mt-4"></div>
                <h5>Veri</h5>
                <p>Understand what you eat. Find your fuel.</p>
                <div className="link-align">
                  <a className="company-link-veri" href="https://veristable.com"><h6>Vieraile sivulla <ArrowRightAltIcon /></h6></a>
                </div>
              </div>
            </Row>
          </Container>
        </Container>
        <img src={wave} alt={""} className="min" ref={contactRef} />
        <Container fluid className="green-bg text-white pb-5">
          <Container>
            <Row className="pb-5">
              <Col lg={6}>
                <Row>
                  <p className="m-rmr" data-aos="fade-up" data-aos-delay="100" >Kiinnostuitko?</p>
                  <h1 data-aos="fade-up" data-aos-delay="100" >Ota yhteyttä<span><img className="dot" src={dot_white} alt={""}/></span></h1>
                </Row>
                <Row className="pt-5" data-aos="fade-up" data-aos-delay="200" >
                  <p>Kerromme mielellämme lisää itsestämme ja palveluistamme, joten jätä meille yhteystietosi ja lyhyt kuvaus tarpeestanne niin olemme teihin yhteydessä. Kuulemisiin!</p>
                </Row>
              </Col>
              <Col lg={6} className="px-4 p-r justify-content-center">
                <form className="contact-form px-4" data-aos="fade-left" data-aos-delay="500" >
                  <Row>
                    <h3 className="text-p pt-4 pb-3">Syötä tietosi</h3>
                    <p className="text-secondary pb-3">Olemme täällä auttaakseemme sinua saavuttamaan tavoitteesi! Syötä yhteystietosi alla olevan lomakkeen kautta.</p>
                  </Row>
                  <Row className="justify-content-center">
                    <ContactForm />
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="grey-bg text-secondary"> 
          <Row className="pt-5 pb-4 text-center">
            <p className="footer-p"><span><img src={trinode_grey} alt={""} className="trinode-grey" /></span>Trinode Software Oy</p>
          </Row>
          <Row className="footer-pl text-center">
            <Col sm={4}>
              <h5><b>Navigointi</b></h5>
              <ul>
                <li><Link className='footerLink pt-2' to='/' onClick={() => handleScrollTo(homeRef)}>Koti</Link></li>
                <li><Link className='footerLink' to='/' onClick={() => handleScrollTo(serviceRefLarge, serviceRefSmall)}>Palvelumme</Link></li>
                <li><Link className='footerLink' to='/' onClick={() => handleScrollTo(aboutRef)}>Meistä</Link></li>
                <li><Link className='footerLink' to='/' onClick={() => handleScrollTo(customerRef)}>Asiakkaamme</Link></li>
                <li><Link className='footerLink' to='/' onClick={() => handleScrollTo(contactRef)}>Ota yhteyttä</Link></li>
                {/* <li><Link className='footerLink' to='/'>In English</Link></li> */}
              </ul>
            </Col>
            <Col sm={4}>
              <h5><b>Yhteystiedot</b></h5> 
              <Row className="pt-2">
                <p className="negative-p-2">+358 503224203</p> 
                <a className="text-secondary footer-l" href="mailto:info@trinode.fi"><p className="footerLink">info@trinode.fi</p></a> 
              </Row> 
              <Row className="negative-p">
                <p>Y-tunnus: 3199637-3</p>
              </Row>
              <Row className="negative-p">
                <p>Perilänkuja 6 A 5</p>
              </Row>  
              <Row className="negative-p">
                <p>02600 Espoo</p>
              </Row>   
              <Row>
                <p>FINLAND</p>
              </Row>     
            </Col>
            <Col sm={4}>
              <h5><b>Sosiaalinen media</b></h5>
              <Row className="justify-content-center pt-2">
                <a className="text-secondary br-r-1" href="https://www.linkedin.com/company/trinode-software-oy"><LinkedInIcon style={{fontSize: 40}} /></a>
                <a className="text-secondary br-r-2" href="https://instagram.com/korkeakouluun"><InstagramIcon style={{fontSize: 40}} /></a>
              </Row>
            </Col>
          </Row>
        <Container>
          <hr className="footer-hr"/>
          <Row className="text-secondary text-center py-2">
            <p>© Copyright 2021 - kaikki oikeudet pidätetään</p>
          </Row>
        </Container>
        </Container>
      </Router>
    </div>
  );
}

export default App;
