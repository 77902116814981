import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

// Add the Firebase products that you want to use
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB9r4nvkf9DxiuWZH8NcuSDQuRYC_esbfA",
  authDomain: "trinode-homepage.firebaseapp.com",
  projectId: "trinode-homepage",
  storageBucket: "trinode-homepage.appspot.com",
  messagingSenderId: "383627603736",
  appId: "1:383627603736:web:a55f70b2b0982d82a2786d",
  measurementId: "G-961SB26XC7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize analytics
const analytics = getAnalytics()

export const createContact = async (data) => {
  const citiesCol = collection(db, 'mail');
  await addDoc(citiesCol, data);
}